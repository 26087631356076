import { render, staticRenderFns } from "./AddressFrame.vue?vue&type=template&id=2db97056&scoped=true&"
import script from "./AddressFrame.vue?vue&type=script&lang=js&"
export * from "./AddressFrame.vue?vue&type=script&lang=js&"
import style0 from "./AddressFrame.vue?vue&type=style&index=0&id=2db97056&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2db97056",
  null
  
)

export default component.exports