<template>
  <div id="address-frame">
    <div class="company">
      vtc-net&nbsp;&nbsp;*&nbsp;&nbsp;Petersbergstr. 70&nbsp;&nbsp;*&nbsp;&nbsp;53177 Bonn
    </div>
    <br>
    <div class="to">
      {{ $t('male') }} {{ customer.username }}
      <br>
      {{ customerProfile.street }} {{ customerProfile.house_number }}
      <br>
      <br>
      {{ customerProfile.postal }} {{ customerProfile.location }}
      <br>
      {{ customerProfile.country }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddressFrame',
  props: {
    room: {
      type: Object
    },
    customer: {
      type: Object
    },
    customerProfile: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
#address-frame{
  margin-top: 80px;
  width: 350px;
  height: 250px;
  padding: 10px 10px;
  border: 1px #000000 solid;
  .company{
    font-size: 12px;
  }
  .to{
    font-size: 16px;
  }
}
</style>
