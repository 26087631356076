<template>
  <div>
    <div class="row">
      <div class="col s12 m6 l4">
        <b>{{ $t('company.title') }}</b>
        <br>
        {{ $t('company.name') }}
        <br>
        {{ $t('company.street') }}
        <br>
        {{ $t('company.location') }}
        <br><br>
        {{ $t('company.director')}}
        <br><br>
        {{ $t('company.register') }}
        <br>
        {{ $t('company.register_number') }}
      </div>
      <div class="col s12 m6 l4">
        <b>{{ $t('finance.title') }}</b>
        <br>
        {{ $t('finance.bank') }}
        <br>
        {{ $t('finance.account') }}
        <br>
        {{ $t('finance.code') }}
        <br>
        {{ $t('finance.iban') }}
        <br>
        {{ $t('finance.bic') }}
        <br><br>
        {{ $t('finance.vat') }}
      </div>
      <div class="col s12 m6 l4">
        <b>{{ $t('support.title') }}</b>
        <br>
        {{ $t('support.email') }}
        <br>
        {{ $t('support.tel') }}
        <br>
        {{ $t('support.fax') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InvoiceFooter'
}
</script>

<style scoped>
.row .col{
  padding: 0;
}
</style>
