<template>
<div>
  <div class="invoice-feature">
    <h4>{{ $t('invoice.title') }}</h4>
    <div class="row">
      <div class="col s12 m6 l4">
        {{ $t('invoice.customer_id') }}: {{ customerNumber }}
        <br>
        <nobr style="font-size: 11px;">{{ $t(invoice.on_contact) }}</nobr>
      </div>
      <div class="col s12 m6 l4" style="text-align: center">
        {{ $t('invoice.bill_id') }}: {{ billNumber }}
      </div>
      <div class="col s12 m6 l4" style="text-align: right">
        {{ $t('invoice.date') }}: {{ dateNow }}
      </div>
    </div>
  </div>
  <div class="invoice-details">
    <b>{{ $t('invoice.hiring') }}</b>
    <br>
    {{ $t('invoice.room_name') }}: {{ room.slug }}
    <br>
    {{ $t('invoice.from') }}: {{ firstDate }}
    <br>
    {{ $t('invoice.to') }}: {{ lastDate}}
    <br>
    <br>
    <div class="row">
      <div class="col s6">
        <b>{{ $t('invoice.total') }}:</b>
        <br>
        {{ $t('invoice.vat') }}:
      </div>
      <div class="col s6" style="text-align: right">
        <b>{{ total }} EUR</b>
        <br>
        {{ vat }} EUR
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'InvoiceBody',
  props: {
    room: {
      type: Object
    },
    firstDate: {
      type: String
    },
    lastDate: {
      type: String
    },
    total: {
      type: String
    }
  },

  computed: {
    customerNumber () {
      return this.$store.getters['auth/customerNumber']
    },
    billNumber () {
      return this.$store.getters['rooms/billNumber']
    },
    dateNow () {
      return DateTime.local().toFormat('dd.MM.yyyy')
      // let now = new Date()
      // return ('0' + now.getDate()).slice(-2) + '.' +
      //        ('0' + (now.getMonth() + 1)).slice(-2) + '.' +
      //        now.getFullYear()
    },
    vat () {
      return ((this.total / 107) * 7).toFixed(2)
    }
  }
}
</script>

<style scoped>
.invoice-feature >.row{
  border-bottom: 1px solid #585858;
}
.invoice-feature h4{
  margin-top: 80px;
  text-align: center;
  padding-bottom: 50px;
}
.row .col{
  padding: 0;
}
</style>
