<template>
  <div id="paid-mail">
    <div id="invoice-content">
      <div id="invoice-header">
        <div class="row">
          <div class="col s12 m6">
            <AddressFrame :room="coreData.room" :customer="coreData.customer" :customerProfile="coreData.customerProfile"></AddressFrame>
          </div>
          <div class="logo col s12 m6">
            <img src="../../../assets/logo.png" alt="">
          </div>
        </div>
      </div>
      <div id="invoice-main">
        <InvoiceBody :room="coreData.room" :firstDate="coreData.firstDate" :lastDate="coreData.lastDate" :total="coreData.total"></InvoiceBody>
      </div>
      <div id="invoice-footer" class="page-footer white black-text">
        <hr>
        <InvoiceFooter></InvoiceFooter>
      </div>
    </div>
    <div class="invoice-pdf">
      Mack PDF to Download
      <div class="center" style="margin-top: 20px">
        <a href="#" class="btn orange lighten-2 text-white z-depth-0"
           @click="downloadBill"
        >
          <i class="fas fa-download"></i> {{ $t('download') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import AddressFrame from '@/components/rooms/payments/partials/AddressFrame'
import InvoiceBody from '@/components/rooms/payments/partials/InvoiceBody'
import InvoiceFooter from '@/components/rooms/payments/partials/InvoiceFooter'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
export default {
  name: 'PaymentMail',
  data () {
    return {
      coreData: {},
      isDownloaded: false
    }
  },
  components: {
    AddressFrame,
    InvoiceBody,
    InvoiceFooter
  },
  mounted () {
    this.coreData = this.$store.getters['rooms/coreData']
    this.buildHirerMail()
    this.buildCustomerMail()
  },
  methods: {
    downloadBill () {
      let pillName = this.$i18n.invoice.title + 'V' + this.coreData.room.slug + '.pdf'
      html2canvas(document.querySelector('#invoice-content'), {
        allowTaint: true,
        useCORS: true,
        scale: 1
      }).then(canvas => {
        const img = canvas.toDataURL('image/png')
        /* eslint-disable */
        const doc = new jsPDF()
        doc.getFont()
        doc.getFontSize()
        doc.addImage(img, 'PNG', 7, 13, 195, 105)
        doc.save(pillName)
      }).then(() => {
        this.isDownloaded = true
        this.$router.push({name: 'home'})
      })
    },
    buildHirerMail () {
      // console.log('Hirer mail will be sent')
      const hirerMailContent = {
        from: this.companyMail,
        company: this.fromCompanyName,
        to: this.toHirerMail,
        name: this.toHirerName,
        bcc: this.companyMail,
        subject: this.hirerMailSubject,
        content: this.hirerMailContent
      }
      this.$store.dispatch('sendMail', hirerMailContent)
    },
    buildCustomerMail () {
      // console.log('Customer mail will be sent')
      const customerMailContent = {
        from: this.companyMail,
        company: this.fromCompanyName,
        to: this.toCustomerMail,
        name: this.toCustomerName,
        bcc: this.companyMail,
        subject: this.customerMailSubject,
        body: this.customerMailContent
      }
      this.$store.dispatch('sendMail', customerMailContent)
    },
  },
  computed: {
    companyMail () {
      return 'jarrar@vtc-net.de'
    },
    fromCompanyName () {
      return 'vtc-net, Ibrahim Jarrar'
    },
    toHirerName () {
      return this.coreData.hirer.username
    },
    toHirerMail () {
      return this.coreData.hirer.email
    },
    hirerMailSubject () {
      return this.$i18n.t('room.reservation_of') + ' ' + this.coreData.room.title + ', ' + this.coreData.firstDate + ' - ' + this.coreData.firstDate
    },
    hirerMailContent () {
      // greetings like: Dear Mr. Mayer,
      let greet = this.i18n.t('m_greet')
      if (this.coreData.hirerProfile.gender === 'female'){
        greet = this.i18n.t('f_greet')
      }
      greet += ' ' + this.coreData.hirer.surname + ',<br><br>'

      // Renter full name
      let renter = this.i18n.t('male')
      if (this.coreData.customerProfile.gender === 'female'){
        renter = this.i18n.t('female')
      }
      renter += ' ' + this.coreData.customer.username

      // Mail text to landlord (hirer)
      let bookedObject = this.i18n.t('l_booked_object') + '<br>'
      let takeContact = this.i18n.t('l_take_contact') + '<br>'
      let contactData = this.i18n.t('contact_data') + '<br>'
      let l_email = 'Email: ' + this.coreData.customer.email + '<br>'
      let l_tel = 'T: ' + this.coreData.customerProfile.mobile + '<br><br>'

      // best regard
      let regards = this.i18n.t('best_regards') + '<br>' +
        '<img src=\"./images/logoBNA.jpg\" style=\"width: 60px;\"></img>' + '<br>' +
        this.fromCompanyName

      // Email Content
      return greet + renter + ' ' + bookedObject + takeContact + contactData + l_email + l_tel + regards
    },
    toCustomerName () {
      return this.coreData.customer.username
    },
    toCustomerMail () {
      return this.coreData.user.email
    },
    customerMailSubject () {
      return this.$i18n.t('room.reservation_of') + ' ' + this.coreData.room.title + ', ' + this.coreData.firstDate + ' - ' + this.coreData.firstDate
    },
    customerMailContent () {
      // greetings like: Dear Mr. Mayer,
      let greet = this.i18n.t('m_greet')
      if (this.coreData.customerProfile.gender === 'female'){
        greet = this.i18n.t('f_greet')
      }
      greet += ' ' + this.coreData.customer.surname + ',<br><br>'

      // Hirer full name
      let hirer = this.i18n.t('male')
      if (this.coreData.hirerProfile.gender === 'female'){
        hirer = this.i18n.t('female')
      }
      hirer += ' ' + this.coreData.hirer.username

      // Mail text to Hirer
      let bookedObject = this.i18n.t('c_booked_object') + '<br>'
      let takeContact = this.i18n.t('c_take_contact') + '<br>'
      let contactData = this.i18n.t('contact_data') + '<br>'
      let h_contact = this.i18n.t('contact_person') + ' ' + hirer + '<br'
      let h_email = 'Email: ' + this.coreData.hirer.email + '<br>'
      let h_tel = 'T: ' + this.coreData.hirerProfile.mobile + '<br><br>'

      // best regard
      let regards = this.i18n.t('best_regards') + '<br>' +
        '<img src=\"./images/logoBNA.jpg\" style=\"width: 60px;\"></img>' + '<br>' +
        this.fromCompanyName

      // Email Content
      return greet + bookedObject + takeContact + contactData + h_contact + h_email + h_tel + regards
    }
  },
  watch: {
    isDownloaded (val) {
      if(val === true){
        M.toast({html: this.$i18n.t('payment.invoice_downloaded'), classes: 'rounded orange-text'})
      }
    }
  }
}
</script>

<style>
#invoice-content{
  font-family: Avenir, Helvetica, Arial, sans-serif;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin: 0 50px 0 80px;
  height: 11in;
  width: 8.5in;
}
#invoice-header{
  padding: 80px;
}
#invoice-header img{
  display: block;
  margin: 0 auto;
}
#invoice-main{
  flex: 1 0 auto;
}
#invoice-footer{
  position: absolute;
  bottom: 0;
  width: 100%;
}
.invoice-pdf{
  position: relative;
}
</style>
